import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

const vision_query = graphql`
query VisionQuery {
	wpComponent(databaseId: {eq: 1788}) {
		content
	}
}
`;

const Vision = () => {
	const data = useStaticQuery(vision_query);

	const vision = data.wpComponent.content

	return (
		<div id="vision">
			<div dangerouslySetInnerHTML={{ __html: vision }}/>
		</div>
	);
};

export default Vision;