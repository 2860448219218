import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const keyPeople_query = graphql`
	query KeyPeopleQuery {
		allWpKeyPeople(sort: {fields: menuOrder}) {
			nodes {
				title
				featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE,
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
				content
				menuOrder
				keyPeopleExtra {
					linkedin
				}
			}
		}
	}
`;

const KeyPeople = () => {
	const data = useStaticQuery(keyPeople_query);

	const keyPeople = data.allWpKeyPeople.nodes;

	const sortedKeyPeople = keyPeople.sort((a, b) => {
		let ppl1 = a.menuOrder+1;
		let ppl2 = b.menuOrder+1;
		return (ppl1 < ppl2 ? -1 : 0)
	})

	return (
		<div>
			<h2>Key People</h2>
			<ul className="keyPeopleList">
				{sortedKeyPeople.map((keypeople, i) => {
					return (
						i % 2 === 0 ?
						<li
							key={i}
							className="singleKeyPeople odd"
						>
							<div className="keyPeoplePicture">
								{keypeople.featuredImage !== null &&
									<GatsbyImage image={getImage(keypeople.featuredImage.node.localFile)} alt={keypeople.title} />
								}
							</div>
							<div className="keyPeopleContent">
								<h3>
									{keypeople.title}
									<a href={keypeople.keyPeopleExtra.linkedin} target="_blank" rel="noreferrer" className="linkedinLink" aria-label="Linkedin link">
										<FontAwesomeIcon icon={faLinkedin} size="1x" width="13" />
									</a>
								</h3>
								<div className="description" dangerouslySetInnerHTML={{ __html: keypeople.content }}></div>
							</div>
						</li>
						:
						<li
							key={i}
							className="singleKeyPeople even"
						>
							<div className="keyPeopleContent">
								<h3>
									<a href={keypeople.keyPeopleExtra.linkedin} target="_blank" rel="noreferrer" className="linkedinLink" aria-label="Linkedin link">
										<FontAwesomeIcon icon={faLinkedin} size="1x" width="13" />
									</a>
									{keypeople.title}
								</h3>
								<div className="description" dangerouslySetInnerHTML={{ __html: keypeople.content }}></div>
							</div>
							<div className="keyPeoplePicture">
								{keypeople.featuredImage !== null &&
									<GatsbyImage image={getImage(keypeople.featuredImage.node.localFile)} alt={keypeople.title} />
								}
							</div>
						</li>
					)
				})}
			</ul>
		</div>
	);
}

export default KeyPeople;