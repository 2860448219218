import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import _ from 'lodash'

const pastConferences_query = graphql`
	query PastConferencesQuery {
		wpgraphql {
			conferences(last: 500) {
			  	nodes {
					title
					menuOrder
					conferencesExtra {
						conferenceName
						location
						month
						year
						day
					}
			  	}
			}
		}
	}
	`;
	// allWpConference(limit: 500, sort: {fields: title, order: ASC}) {
	// 	nodes {
	// 		conferencesExtra {
	// 			location
	// 			month
	// 			year
	// 			day
	// 			conferenceName
	// 		}
	// 	}
	// }
	
const groupBy = (groupedArray) => {
	return groupedArray.reduce((array, arrayItem) => {
		// console.log(array);
		// console.log(array[arrayItem.conferencesExtra.year]);
		// console.log(arrayItem);
		(array[arrayItem.conferencesExtra.conferenceName] = array[arrayItem.conferencesExtra.conferenceName] || []).push(arrayItem);
		return array;
	}, {});
};

const PastConferences = () => {
	const data = useStaticQuery(pastConferences_query);

	const pastConferences = data.wpgraphql.conferences.nodes;

	// const today = new Date();

	// filter out future conferences
	// const filteredPastConferences = pastConferences.filter((conf) => {
	// 	const date = [
	// 		conf.conferencesExtra.year,
	// 		conf.conferencesExtra.month === null ? 1 : conf.conferencesExtra.month-1,
	// 		conf.conferencesExtra.day === null ? 1 : conf.conferencesExtra.day
	// 	];
	// 	let confDate = new Date(date);
	// 	return confDate < today
	// })

	// sort conferences by year
	const filteredSortedPastConferences = pastConferences.sort((a, b) => {
		let confDate1 = new Date(a.conferencesExtra.year);
		let confDate2 = new Date(b.conferencesExtra.year);
		return (confDate2 < confDate1 ? -1 : 0)
	})

	// group conferences by name
	const filteredGroupedPastConferences = groupBy(filteredSortedPastConferences);

	// create a matrix with conference names and the number of attendances
	const confLengthMap = Object.keys(filteredGroupedPastConferences).map((conf) => {
		return [conf, filteredGroupedPastConferences[conf].length]
	})

	// const shuffledConfLengthMap = _.shuffle(confLengthMap);

	// sort conferences by number of attendances
	const sortedConfLengthMap = confLengthMap.sort((a, b) => {
		return b[0] > a[0] ? -1 : 0
	})

	const confsChuncks = _.chunk(sortedConfLengthMap, _.ceil(sortedConfLengthMap.length/3));

	return (
		<div id="pastConferences">
			<h2>M1ND-SET SPEAKING AT CONFERENCES</h2>
			<div id="pastConferencesListWrapper">
				<div id="pastConferencesList">
					{confsChuncks.map((confsChunk, c) => {
						return (
						<div
							className={`column column${c+1}`}
							key={c}
						>
							{confsChunk.map((confmap, i) => {
								return (
									<div
										key={i}
										className={`pastConference`}
									>
										<h3>{confmap[0]}</h3>
										<div className="pastConferenceYearList" >
											{filteredGroupedPastConferences[confmap[0]].map((conf, j) => {
											return (
												<span
													key={`${confmap[0]}${i}${j}`}
													className="pastConferenceYear"
												>
													{/* {conf.conferencesExtra.day === null ? "" : `${conf.conferencesExtra.day}.`}
													{conf.conferencesExtra.month === null ? "" : `${conf.conferencesExtra.month}.`} */}
													{conf.conferencesExtra.year}
												</span>
											)
										})}
										</div>
									</div>
								)
							})}
						</div>
						)
					})}
				</div>
			</div>
		</div>
	);
};

export default PastConferences;