import * as React from "react"
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import PastConferences from '../components/pastConferences'
import KeyPeople from '../components/keyPeople'
import Vision from '../components/vision'

import { StaticImage } from "gatsby-plugin-image";

const AboutUsHeader = ({ page, contact }) => {
	return (
		<div id="aboutUsHeader">
			<div dangerouslySetInnerHTML={{ __html: page.content }} id="aboutUsContent"/>
			<div id="contactUsContent">
				<h2>{contact.title}</h2>
				<div dangerouslySetInnerHTML={{ __html: contact.content }}/>
			</div>
		</div>
	)
}

const AboutUsPage = ({ data }) => {

	const page = data.wpMainPage;
	const contact = data.wpComponent;

	return (
		<Layout title={page.title} breadcrumbs={[['About us','#']]}>
			<Seo post={page} />
			<div id="aboutUsWrapper">
				<section className="content" id="aboutUs">
					<div id="aboutUsLogo">
						<StaticImage src="../images/logo_new_footer2.png" alt="logo"/>
					</div>
					<Vision/>
					<AboutUsHeader page={page} contact={contact}/>
					<KeyPeople />
					<PastConferences />
				</section>
			</div>
		</Layout>
	)
}

export default AboutUsPage;

export const query = graphql`
	query AboutusPageQuery {
		wpComponent(databaseId: {eq: 1750}) {
			title
			content
		}
		wpMainPage(databaseId: {eq: 95}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;